import { Button } from "components/UI/Button";
import { Modal } from "components/UI/Modal";
import useTranslation from "next-translate/useTranslation";
import React from "react";

interface Props {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

export const ErrorModal = ({ error, resetErrorBoundary }: Props) => {
  const { t } = useTranslation("errors");

  const knownErrors = [
    "GeneratorError",
    "LoadingGardenError",
    "LoadingPlantDbError",
  ];
  const isKnownError = knownErrors.includes(error.name);
  const errorTitle = isKnownError
    ? t(error.name + "Title")
    : t("DefaultErrorTitle");
  const errorMessage = isKnownError
    ? t(error.name + "Message")
    : t("DefaultErrorMessage");
  const errorButton = isKnownError
    ? t(error.name + "Button")
    : t("DefaultErrorButton");
  

  return (
    <Modal isOpen={true}>
      <h2>{errorTitle}</h2>
      <p>{errorMessage}</p>
      <footer>
        <Button onClick={resetErrorBoundary}>{errorButton}</Button>
      </footer>
    </Modal>
  );
};
