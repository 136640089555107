import { Box, Sphere } from "@react-three/drei";
import { NodeData } from "stores/gardenStore";
import { usePlantsStore } from "stores/plantsStore";
import settings from "settings";
import { useStateStore } from "stores/stateStore";
const { squareSize } = settings;

type GardenSquareSimpleProps = {
  nodeData: NodeData;
  position?: [number, number, number];
};

const GardenSquareSimple = ({ nodeData, position = [0,0,0]}: GardenSquareSimpleProps) => {
  const {pids, mat} = nodeData;
  const season = useStateStore(state => state.season);
  const getPlantFloweringSeasons = usePlantsStore(
    (state) => state.getPlantFloweringSeasons
  );
  const pids_season = pids.filter((pid) => getPlantFloweringSeasons(pid).includes(season));
  const getPlantColor = usePlantsStore((state) => state.getPlantColor);
  const getPosX = (i, ln) => {
    return ln === 1 ? 0 : (-0.25 + i * 0.5) * squareSize;
  } 
  return (
    <group position={position}>
      {pids_season.map((pid, i) => (
        <Box
          key={i}
          args={[squareSize / pids.length, 0.05, squareSize]}
          position-x={getPosX(i, pids.length)}
        >
          <meshBasicMaterial color={getPlantColor(pid)} />
        </Box>
      ))}
      {mat !== -1 && (
        <Sphere args={[0.25 * squareSize, 32, 32]}>
          <meshBasicMaterial color={getPlantColor(mat)} />
        </Sphere>
      )}
      {pids_season.length === 0 && (
        <Box
          args={[squareSize, 0.05, squareSize]}
          position-x={0}
          position-y={-0.1}
        >
          <meshBasicMaterial color="hsl(0,0%,98%)" />
        </Box>
      )}
    </group>
  );
};

export default GardenSquareSimple;
