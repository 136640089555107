import { useEffect, useMemo, useRef } from "react";
import { useTexture } from "@react-three/drei";
import { DoubleSide, MathUtils, NearestFilter, Texture } from "three";
import { a, useSpring } from "@react-spring/three";
import { plantSquareGridGeomRand, plantSquareGridGeom } from "./PlantSquareGridGeom";
import settings from "settings";
const { squareSize } = settings;
import { unknownPlant, usePlantsStore } from "stores/plantsStore";
import { useFrame } from "@react-three/fiber";
import "components/Garden/Shaders/PlantMaterial";
import { useStateStore } from "stores/stateStore";
import { isMobile } from "lib/deviceHelper";

const mult = 1;
const speed = 0.3;
const amplitude = 0.2;

const stageLookup = { a: 0, b: 1, c: 2};
//const defaultTexture = "/textures/006_geranium-x-cantabriginiense-biokovo_50cm_b.webp";  // for testing: "/textures/error-texture.png"; 
const defaultTexture = "/textures/error-texture.png";  // for testing: "/textures/error-texture.png"; 
const mapLUTFilename = "/textures/lut-2.png";

const isM = isMobile();

export default function PlantSquare({ plantId, selected = false }) {
  const identifyPlants = useStateStore((state) => state.identifyPlants);
  const getPlantData = usePlantsStore((state) => state.getPlantData);
  const season = useStateStore((state) => state.season);
  const pollinatorView = useStateStore((state) => state.pollinatorView);
  const saturation = 0;
  let plantData = getPlantData(plantId);

  if (!plantData) {
    console.error(`Plant data for ID=${plantId} is undefined.`);
    plantData = unknownPlant;
  }

  let suffix = isM ? '_low' : ''

  //suffix = "_low";
  let springimg = plantData['springimg' + suffix] ?? defaultTexture;
  let summerimg = plantData['summerimg' + suffix] ?? defaultTexture;
  let winterimg = plantData['winterimg' + suffix] ?? defaultTexture;

  //let springimg = plantData['springimgpng' + suffix] ?? defaultTexture;
  //let summerimg = plantData['summerimgpng' + suffix] ?? defaultTexture;
  //let winterimg = plantData['winterimgpng' + suffix] ?? defaultTexture;
  const scale = +plantData?.texturescale ?? 1;

 // console.log(plantData)


  //https://cms.pollinator.art/media/pages/plants/rudbeckia-fulgida/47c96beb19-1633962848/113-rudbeckia-fulgida-100cm-a-1024x1024-q80_bg_removed.png

  const mapFolliage = useTexture(springimg);
  const mapBloom = useTexture(summerimg);
  const mapWinter = useTexture(winterimg);


  const mapLUT = useTexture(mapLUTFilename);
  mapLUT.minFilter = NearestFilter;
  mapLUT.magFilter = NearestFilter;

  const geom = useMemo(() => {
    return plantSquareGridGeomRand({
      width: scale * mult * (1 + amplitude),
      height: scale * mult,
      countX: 2,
      countY: 2,
      gap: squareSize * 0.35,
      margin: squareSize * 0.2,
    });
  }, []);

  const { scaleY, scaleX, scaleZ, positionY } = useSpring({
    from: {
      scaleY: 0.2,
      scaleX: 0.4,
      scaleZ: 0.4,
      positionY: -0.6,
    },
    to: { scaleY: 1, scaleX: 1, scaleZ: 1, opacity: 1, positionY: 0 },
    delay: MathUtils.randInt(0, 800) + 1500,
    config: { mass: 1, tension: 280, friction: 120 },
  });

  const stage = plantData ? stageLookup[plantData["season" + (season + 1)]] : 0;

  const material = useRef(null);

  // randomise waving animation
  useEffect(() => {
    //material.current.uniforms.time.value = MathUtils.randFloat(0, 10);
  }, []);

  useFrame((_, delta) => {
    if (material.current) material.current.uniforms.time.value += delta;
  });

  return (
    <a.mesh
      geometry={geom}
      position-y={positionY}
      scale-y={scaleY}
      scale-x={scaleX}
      scale-z={scaleZ}
    >
      <plantMaterial
        ref={material}
        saturation={saturation}
        amplitude={amplitude}
        speed={speed}
        stage={stage}
        mapFolliage={mapFolliage}
        mapBloom={mapBloom}
        mapWinter={mapWinter}
        pollinatorView={pollinatorView}
        mapLUT={mapLUT}
      />
    </a.mesh>
  );
}
