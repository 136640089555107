import { MathUtils, PlaneBufferGeometry } from "three";
import { mergeBufferGeometries } from "three-stdlib";
const { randInt, randFloatSpread, degToRad } = MathUtils;

type plantSquareGridGeomParams = {
  width: number;
  height: number;
  countX: number;
  countY: number;
  gap: number;
  margin: number;
};

export function plantSquareGridGeom({
  width,
  height,
  countX,
  countY,
  gap,
  margin,
}: plantSquareGridGeomParams) {
  const geometries = [];

  for (let y = 0; y < countY; y++) {
    for (let x = 0; x < countX; x++) {
      const plane = new PlaneBufferGeometry(width, height, 1, 1);
      plane.translate(0, height / 2, 0);
      plane.rotateX(MathUtils.degToRad(-30)); // tilt backward
      plane.rotateY(MathUtils.degToRad(-45));
      plane.translate(x * gap - margin, 0, y * gap - margin);
      geometries.push(plane);
    }
  }
  return mergeBufferGeometries(geometries);
}

export function plantSquareGridGeomRand({
  width,
  height,
  countX,
  countY,
  gap,
  margin,
}: plantSquareGridGeomParams) {
  const geometries = [];

  for (let y = 0; y < countY; y++) {
    for (let x = 0; x < countX; x++) {
      const randSize = randFloatSpread(gap * 0.2);
      const rWidth = width * (1 + randSize);
      const rHeight = height * (1 + randSize);
      const plane = new PlaneBufferGeometry(rWidth, rHeight, 1, 1);
      plane.translate(0, rHeight / 2, 0);
      plane.rotateX(degToRad(randInt(-10,-45))); // tilt backward
      plane.rotateY(degToRad(-45 + randFloatSpread(80)));
      plane.translate(x * gap - margin, 0, y * gap - margin);
      plane.translate(randFloatSpread(gap * 0.2), 0, randFloatSpread(gap * 0.2));
      geometries.push(plane);
    }
  }
  return mergeBufferGeometries(geometries);
}