import { Portal } from "hoc/Portal";
interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  handleClose?: () => void;
  maxWidth?: string;
  style?: "white" | "colour" | "share" | "white no-padding";
  pdf?: boolean;
  agreeTC?: boolean;
}

export const Modal = ({
  children,
  isOpen,
  handleClose,
  maxWidth = "50rem",
  style = "colour",
  pdf = false,
  agreeTC = false,
}: Props) => {
  const onClickBackground = (event) => {
    if (!handleClose) return;
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen) return null;
  return (
    <Portal>
      <div
        className={`Modal ${style} ${agreeTC && pdf && "pdf"}`}
        onClick={onClickBackground}
      >
        <div
          className={`content ${agreeTC && pdf && "pdf"}`}
          style={{ maxWidth }}
        >
          {children}
        </div>
        <style global jsx>{`
          .Modal {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--white-transparent);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
          }

          .Modal.pdf .content {
            width: 100%;
            height: auto;
          }

          .Modal.pdf .content iframe {
            height: 100%;
            width: 100%;
            border: none;
          }

          .Modal .content {
            position: relative;
            width: 100%;
            padding: var(--spacing);
            background-color: var(--blue-light);
            max-height: 100%;
            overflow-y: auto;
            font-family: var(--font-heading-1);
          }
          .Modal .content > *:first-child {
            margin-top: 0;
          }

          // no padding
          .Modal.no-padding .content {
            padding: 0;
          }

          // white
          .Modal.white {
            background-color: var(--black-transparent);
          }
          .Modal.white .content {
            background-color: var(--white);
          }

          .Modal footer {
            display: flex;
            flex-direction: row-reverse;
          }

          .Modal a {
            text-decoration: underline;
          }
          .Modal a:hover {
            color: var(--purple);
          }
        `}</style>
      </div>
    </Portal>
  );
};
