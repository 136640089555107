import create from "zustand";

// Query the cms instead?
// const URL_DB_JSON = process.env.NEXT_PUBLIC_GARDEN_API_ROOT + "/db/json";
const URL_DB_JSON = process.env.NEXT_PUBLIC_CMS_ROOT + "/public/api/plants/all";
console.log(process.env.NEXT_PUBLIC_CMS_ROOT);
function LoadingPlantDbError(message = "") {
  this.name = "LoadingPlantDbError";
  this.message = message;
}
LoadingPlantDbError.prototype = Error.prototype;

export type Plant = {
  title: string;
  botanicalname: string;
  commonname: string;
  othername: string;
  region: number[];
  combinationonly: boolean;
  matrixplant: boolean;
  ismatrix: boolean;
  flowercolour: string[];
  foliagecolour: string[];
  soilrequirements: number[];
  soilph: number[];
  lightrequirements: number[];
  exposure: number[];
  pollinators: number[];
  floweringseasons: number[];
  plantarchitecture: number[];
  traplineplant: boolean;
  springimg: string;
  summerimg: string;
  winterimg: string;
  springimg_low: string;
  summerimg_low: string;
  winterimg_low: string;
  texturescale: number;
  season1: "a" | "b" | "c";
  season2: "a" | "b" | "c";
  season3: "a" | "b" | "c";
  season4: "a" | "b" | "c";
  season5: "a" | "b" | "c";
  season6: "a" | "b" | "c";
  season7: "a" | "b" | "c";
  season8: "a" | "b" | "c";
  season9: "a" | "b" | "c";
  planttext: string;
  plantpollinatorstext: string;
  plantpropagation: string;
  plantcultivation: string;
  plantpests: string;
  plantmaintenance: string;
  plantcategory: string;
  seasonalinterest: string;
  densitypersqm: number;
  plantheight: string;
  plantspread: string;
  plantorigin: string;
  plantingregion: string;
  leaflifecycle: string;
  growthhabit: string;
  flowertype: string;
  id: number;
  plantids: number[];
  slug: string;
  translations: any;
};

export const unknownPlant: Plant = {
  title: "Unknown",
  botanicalname: "Unknown",
  commonname: "Unknown",
  othername: "Unknown",
  region: [0],
  combinationonly: false,
  matrixplant: false,
  ismatrix: false,
  flowercolour: ["#fff"],
  foliagecolour: ["#fff"],
  soilrequirements: [0],
  soilph: [0],
  lightrequirements: [0],
  exposure: [0],
  pollinators: [0],
  floweringseasons: [0],
  plantarchitecture: [0],
  traplineplant: false,
  springimg: "/textures/white.png",
  summerimg: "/textures/white.png",
  winterimg: "/textures/white.png",
  springimg_low: "/textures/white.png",
  summerimg_low: "/textures/white.png",
  winterimg_low: "/textures/white.png",
  texturescale: 1,
  season1: "a",
  season2: "a",
  season3: "a",
  season4: "a",
  season5: "a",
  season6: "a",
  season7: "a",
  season8: "a",
  season9: "a",
  planttext: "Unknown",
  plantpollinatorstext: "Unknown",
  plantpropagation: "Unknown",
  plantcultivation: "Unknown",
  plantpests: "Unknown",
  plantmaintenance: "Unknown",
  plantcategory: "Unknown",
  seasonalinterest: "Unknown",
  densitypersqm: 0,
  plantheight: "Unknown",
  plantspread: "Unknown",
  plantorigin: "Unknown",
  plantingregion: "Unknown",
  leaflifecycle: "Unknown",
  growthhabit: "Unknown",
  flowertype: "Unknown",
  id: -1,
  plantids: [-1],
  slug: "Unknown",
  translations: null,
};

type PlantStoreState = {
  db: Record<string, Plant>;
  matrixIds: Map<number, string>;
  isLoaded: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  fetch: () => Promise<boolean>;
  getPlantData: (plantId: number) => Plant;
  getPlantColor: (plantId: number) => string;
  getPlantFloweringSeasons: (plantId: number) => number[];
};

const getMatrixIds = (db: Record<string, Plant>) => {
  const matrixIds = Object.values(db)
    .filter((i) => i.ismatrix)
    .reduce((acc, val, i) => {
      acc.set(val.id, String.fromCharCode(65 + i));
      return acc;
    }, new Map<number, string>());
  return matrixIds;
};

export const usePlantsStore = create<PlantStoreState>((set, get) => ({
  db: {},
  isLoaded: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
  fetch: async () => {
    if (get().isLoaded) return true;
    set({ isLoading: true });
    try {
      const resp = await fetch(URL_DB_JSON);
      if (!resp.ok) {
        throw new LoadingPlantDbError(resp.statusText);
      }
      const json = await resp.json();
      set({
        isLoading: false,
        db: json,
        isLoaded: true,
        matrixIds: getMatrixIds(json),
      });
      
      return true;
    } catch (error) {
      set({ isLoading: false, isError: true, errorMessage: error.message });
      throw new LoadingPlantDbError(error.message);
    }
  },
  getPlantData: (plantId) => {
    return get().db[plantId];
  },
  getPlantColor: (plantId: number) => {
    const db = get().db;
    if (!db) return "#ccc";
    const plantColor = db?.[plantId]?.flowercolour[0];
    return plantColor ?? "#000000";
  },
  getPlantFloweringSeasons: (plantId: number) => {
    const db = get().db;
    if (!db) return [];
    const floweringseasons = db?.[plantId]?.floweringseasons;
    return floweringseasons ?? [];
  },
  matrixIds: null,
  
}));
