import React, { useLayoutEffect, useRef, Suspense, useState, useEffect } from "react";
import { UIViews, useStateStore } from "stores/stateStore";
import { useGardenStore } from "stores/gardenStore";
import GardenSquare from "../GardenSquare/GardenSquare";
import GardenSquareSimple from "../GardenSquareSimple/GardenSquareSimple";

import settings from "settings";
import { TextureCubeUVNode } from "three-stdlib";
import { Sphere } from "@react-three/drei";
import { DoubleSide } from "three";
const { squareSize } = settings;

const ViewGarden = () => {
  const refCameraTarget = useRef(null);
  const setCameraTarget = useStateStore((state) => state.setCameraTarget);
  const identifyPlants = useStateStore((state) => state.identifyPlants);
  const view = useStateStore((state) => state.view);
  const nodes = useGardenStore((state) => state.nodes);
  const width = useGardenStore((state) => state.settings.width);
  const height = useGardenStore((state) => state.settings.height);
  const getCoordForNodeId = useGardenStore((state) => state.getCoordForNodeId);
  const [selected, setSelected] = useState(null);
  const setHoveredPlant = useGardenStore((state) => state.setHoveredPlant);

  useLayoutEffect(() => {
    setCameraTarget(refCameraTarget.current);
  }, []);
  const getPos = (nodeId: number): [number, number, number] => {
    const { x, y } = getCoordForNodeId(nodeId);
    return [x * squareSize, 0, -y * squareSize];
  };
  const GardenSquareType =
    view === UIViews.ViewGardenDiagram ? GardenSquareSimple : GardenSquare;

  const handleEnter = (nodeId, nodeData) => (e) => {
    e.stopPropagation();
    if (!identifyPlants) return;
    setSelected(nodeId);
    setHoveredPlant(nodeData, { x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.body.style.cursor = "move";
    return () => {
      document.body.style.cursor = "auto";
    }
  }, [])

  return (
    <Suspense fallback={null}>
      <group
        ref={refCameraTarget}
        position-x={squareSize * 0.5}
        position-z={-squareSize * 0.5}
        visible={view !== UIViews.LoadingGarden}
      >
        {nodes && Object.entries(nodes).map(([nodeId, nodeData]) => (
          <group key={nodeId} position={getPos(+nodeId)}>
            <Sphere
              onPointerEnter={handleEnter(nodeId, nodeData)}
              args={[squareSize * 0.7, 16, 16]}
              position-y={squareSize * 0.25}
              renderOrder={100}
              visible={nodeId === selected && identifyPlants}
            >
              <meshBasicMaterial
                color={"yellow"}
                opacity={0.25}
                transparent={true}
                side={DoubleSide}
                depthTest={false}
              />
            </Sphere>
            <GardenSquareType nodeData={nodeData} />
          </group>
        ))}
      </group>
    </Suspense>
  );
};

export default React.memo(ViewGarden);
