import React, { useState } from "react";
import { Box } from "@react-three/drei";
import { NodeData, useGardenStore } from "stores/gardenStore";
import PlantSquare from "../PlantSquare/PlantSquare";
import { DoubleSide } from "three";
import { useStateStore } from "stores/stateStore";

import settings from "settings";
const { squareSize } = settings;

type GardenSquareProps = {
  nodeData: NodeData;
  position?: [number, number, number];
};

const GardenSquare = ({ nodeData, position = [0,0,0]}: GardenSquareProps) => {
  const { pids, mat } = nodeData;
  
  return (
    <group position={position}>
      {pids.map((pid, i) => (
        <PlantSquare key={pid} plantId={pid} />
      ))}
      <Box
        args={[squareSize, squareSize, squareSize]}
        position-y={-squareSize * 0.5 - 0.03}
      >
        <meshBasicMaterial color={"white"} />
      </Box>
    </group>
  );
};

export default GardenSquare;
